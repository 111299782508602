<template>
  <navigation index="clients" ref="navigation">
    <div class="topnavigation">
        <el-button type="primary" @click="handleAdd"> {{  tools.GetLanguageValue('web.新增')  }}</el-button>
        <el-input
          :prefix-icon="Search"
          :placeholder=" tools.GetLanguageValue('web.请输入') "
          v-model="queryWord"
          @input="handleQuery"
          style="width: 200px; margin-left: 10px"
        >
          <template #prefix>
            <el-icon class="el-input__icon"><search /></el-icon>
          </template>
        </el-input>
      </div>
      <el-row :gutter="20">
        <el-col>
          <el-table
            border
            :data="clientDatas"
            style="width: 100%"
            :style="{ height: ContentHeight + 'px' }"
          >
            <el-table-column type="index" width="50" />
            <el-table-column
              width="150"
              prop="Name"
              :label=" tools.GetLanguageValue('web.姓名') "
            ></el-table-column>
            <el-table-column
              width="100"
              prop="LevelName"
              :label=" tools.GetLanguageValue('web.用户类型') "
            ></el-table-column>
            <el-table-column
              width="150"
              prop="PhoneNumber"
              :label=" tools.GetLanguageValue('web.电话') "
            ></el-table-column>
            <el-table-column
              width="150"
              prop="Email"
              :label=" tools.GetLanguageValue('web.电子邮件') "
            ></el-table-column>
            <el-table-column
              width="300"
              prop="Address"
              :label=" tools.GetLanguageValue('web.地址') "
            ></el-table-column>
            <el-table-column
              width="150"
              prop="DocNumber"
              :label=" tools.GetLanguageValue('web.证件') "
            ></el-table-column>
            <el-table-column :label=" tools.GetLanguageValue('web.操作') " width="300" fixed="right">
              <template #default="{ row }">
                <el-button size="small" type="primary" @click="editClick(row)"
                  >{{  tools.GetLanguageValue('web.编辑')  }}</el-button
                >
                <el-button size="small" type="danger" @click="deleteClick(row)"
                  >{{  tools.GetLanguageValue('web.删除')  }}</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div style="margin: 5px">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="padding.Page"
              :page-sizes="[10, 20, 30, 40]"
              :page-size="padding.Size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="padding.Count"
            ></el-pagination>
          </div>
        </el-col>
      </el-row>

      <el-dialog :title="title" v-model="showModel" width="1000px">
        <el-form :model="form" style="margin: 15px">
          <el-row :gutter="20">
            <el-col :lg="12">
              <el-form-item>
                <el-input
                  v-model="form.Name"
                  :placeholder=" tools.GetLanguageValue('web.请输入名称')  "
                  @focus="InputSelect($event)"
                >
                  <template #prefix>
                    <el-icon><Avatar /></el-icon>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12">
              <el-form-item>
                <el-input
                  v-model="form.ChineseName"
                  :placeholder=" tools.GetLanguageValue('web.请输入中文名称')  "
                  @focus="InputSelect($event)"
                >
                  <template #prefix>
                    <el-icon><Avatar /></el-icon>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12">
              <el-form-item>
                <el-input
                  v-model="form.PhoneNumber"
                  :placeholder=" tools.GetLanguageValue('web.请输入联系电话') "
                  @focus="InputSelect($event)"
                >
                  <template #prefix>
                    <el-icon><PhoneFilled /></el-icon>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12">
              <el-form-item>
                <el-input
                  v-model="form.Email"
                  :placeholder=" tools.GetLanguageValue('web.请输入电子邮件') "
                  @focus="InputSelect($event)"
                >
                  <template #prefix>
                    <el-icon><Message /></el-icon>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12">
              <el-input
                v-model="form.DocNumber"
                :placeholder=" tools.GetLanguageValue('web.请输入证件号') "
                @focus="InputSelect($event)"
                class="input-with-select"
              >
                <template #prepend>
                  <el-select v-model="form.DocType" style="width: 120px">
                    <el-option
                      v-for="item in docTypeOptions"
                      :key="item.Key"
                      :label="item.Value"
                      :value="item.Key"
                    ></el-option>
                  </el-select>
                </template>
                <template #prefix>
                  <el-icon><Postcard /></el-icon>
                </template>
              </el-input>
 
            </el-col>
            <el-col :lg="12">
         
              <el-form-item>
                <el-input
                  v-model="form.Address"
                  :placeholder=" tools.GetLanguageValue('web.请输入地址') "
                  @focus="InputSelect($event)"
                >
                  <template #prefix>
                    <el-icon><Location /></el-icon>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :lg="12">
              <el-form-item :label=" tools.GetLanguageValue('web.用户类型') ">
                <el-select v-model="form.Level">
                  <el-option
                    v-for="item in clientLevelOptions"
                    :key="item.Key"
                    :label="item.Value"
                    :value="item.Key"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :lg="12">
              <el-form-item :label=" tools.GetLanguageValue('web.备注') ">
                <el-input
                  v-model="form.Desc"
                  type="textarea"
                  @focus="InputSelect($event)"
                  :placeholder=" tools.GetLanguageValue('web.请输入备注') "
                >
                  <template #prefix>
                    <el-icon><Location /></el-icon>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancel">{{  tools.GetLanguageValue('web.取消')  }}</el-button>
            <el-button type="primary" @click="submitForm">{{  tools.GetLanguageValue('web.确定')  }}</el-button>
          </span>
        </template>
      </el-dialog>
  </navigation>
</template>
<script>
import axios from "../../../commons/AxiosMethod.js";
import tools from "../../../commons/tools.js";
import navigation from "../../../components/Modules/Navigation.vue";
import { ElMessageBox } from "element-plus";
import commons from "../../../commons/commons.js";
import {
  Search,
  Delete,
  Avatar,
  PhoneFilled,
  Message,
  CreditCard,
  Position,
  Location,
  Postcard,
  Star,
} from "@element-plus/icons-vue";
export default {
  data() {
    return {
      clientWidth: document.documentElement.clientWidth,
      clientHeight: document.documentElement.clientHeight,
      tools:tools,
      beforeData: {}, //修改前数据
      title: "", // 弹出层标题
      showModel: false, // 是否显示弹出层
      clientDatas: [], //客户数据列表
      clientLevelOptions: [], //客户级别选项数据
      docTypeOptions: [], //证件类型选项数据
      queryWord: "", //查询输入文字
      form: {}, // 表单参数
      //分页信息
      padding: {
        Page: 1,
        Size: 10,
        Count: 0,
      },
      ContentHeight: 900,
    };
  },
  components: {
    navigation,
    Search,
    Delete,
    Avatar,
    PhoneFilled,
    Message,
    CreditCard,
    Position,
    Location,
    Postcard,
    Star,
  },
  mounted() {
    this.GetClientDocTypeEnum();
    this.ClientLevelEnum();
    this.GetClientList();

    window.onresize = () => {
      //屏幕尺寸变化就重新赋值
      return (() => {
        this.clientHeight = document.documentElement.clientHeight;
        this.ContentHeight = document.documentElement.clientHeight - 120;
        this.$refs.navigation.onresize();
      })();
    };
    this.ContentHeight = this.clientHeight - 120;
  },
  methods: {
    InputSelect(e) {
      e.currentTarget.select();
    },
    //获取证件类型
    GetClientDocTypeEnum() {
      axios.apiMethod(
        "/sys/SystemData/GetClientDocTypeEnum",
        "get",
        null,
        (result) => {
          this.docTypeOptions = result.Data;
        },
        (error) => {}
      );
    },
    //获取客户级别
    ClientLevelEnum() {
      axios.apiMethod(
        "/sys/SystemData/GetClientLevelEnum",
        "get",
        null,
        (result) => {
          this.clientLevelOptions = result.Data;
        },
        (error) => {}
      );
    },
    //获取列表数据
    GetClientList() {
      this.padding.Where = {
        AnyWord: this.queryWord,
      };
      axios.apiMethod(
        "/stores/Client/GetList",
        "post",
        this.padding,
        (result) => {
          if (result.Code == 200) {
            this.clientDatas = result.Data.Datas;
            this.padding.Page = result.Data.Page;
            this.padding.Size = result.Data.Size;
            this.padding.Count = result.Data.Count;
          }
        }
      );
    },
    /** 搜索按钮操作 */
    handleQuery() {
      console.log("ssss");
      this.padding.Page = 1;
      this.GetClientList();
    },
    //页大小改变
    handleSizeChange(val) {
      this.padding.Size = val;
      this.GetClientList();
    },
    //当前页改变
    handleCurrentChange(val) {
      this.padding.Page = val;
      this.GetClientList();
    },
    /*提交按钮*/
    submitForm() {
      // console.log(JSON.stringify(this.form));
      // return;
      axios.apiMethod(
        "/stores/Client/AddOrEditClient",
        "post",
        this.form,
        (result) => {
          if (result.Code == 200) {
            this.GetClientList();
            //用elementui的弹窗提示成功然后再关掉模态框
            ElMessageBox({
              title: "提示",
              message: "操作成功",
            });
            this.showModel = false;
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error",
            });
          }
        }
      );
    },
    //重置表单
    resetForm(refName) {
      console.log("表单重置");
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
    //取消按钮
    cancel() {
      this.reset();
      this.showModel = false;
    },
    /** 新增按钮操作 */
    handleAdd() {
      this.reset();
      this.GetClientDocTypeEnum();
      this.ClientLevelEnum();
      this.showModel = true;
      this.title = "新增";
    },
    //删除数据
    deleteClick(row) {
      console.log(row);
      //删除的时候提示
      commons.confirmDelete(row.Name, () => {
        axios.apiMethod(
          "/stores/Client/DeleteClient?Id=" + row.Id,
          "get",
          {},
          (result) => {
            if (result.Code == 200) {
              ElMessageBox({
                message: "成功",
                type: "error",
              });
              this.GetClientList();
            } else {
              ElMessageBox({
                message: result.FullMsg,
                type: "error",
              });
            }
          }
        );
      });
    },
    /*修改按钮操作*/
    editClick(data) {
      this.form = JSON.parse(JSON.stringify(data));
      //打开模态框;
      this.showModel = true;
      // this.form = data;
      this.title = "编辑";
    },
    // 表单重置
    reset() {
      this.form = {
        Id: undefined,
        Address: undefined,
        ChineseName: undefined,
        DocNumber: undefined,
        DocType: 0,
        Email: undefined,
        Level: 0,
        Name: undefined,
        PhoneNumber: undefined,
      };
      this.resetForm("form");
    },
  },
};
</script>
<style scoped>
.header {
  height: 50px;
  line-height: 50px;
}
</style>